body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

span { 
    display: inline-block;
    /* word-wrap:break-word; */
  }

h1 {
  margin: 1rem;
}

h3 {
  margin: 2rem auto 3rem;
}

h4 {
  margin: 1.25rem;
}

h6 {
  margin: -.5rem;
}

p {
  margin: 1.5rem auto auto;
}

button {
  padding: 1rem;
  margin: .5rem 1rem 0;
  font-size: 1.5rem;
  cursor: pointer;
  background-color: #9DA65B;
  border: none;
  color: #F2EBDF;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid #733934;
  border-radius: 5px;
  outline: none;
}

.inProgressButton {
  padding: .85rem 1rem;
}

/* Home Page PSA Overlay Public Service Announcement */
.HomePSAContainer {
  position: relative;
  width: 90%;
}

.HomePSAOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #F2EBDF;
}

.HomePSAContainer:hover .HomePSAOverlay {
  opacity: .9;
  border: 2px solid #733934;
  border-radius: 5px;
  /* margin: .25rem 0 0 0; */
}

.HomePSAOverlayText {
  color: #733934;
  font-size: 1.5rem;
  padding: 1.5rem;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

/* Superheros Page PSA Overlay Public Service Announcement */
.SuperherosPSAContainer {
  position: relative;
  /* width: 90%; */
}

.SuperherosPSAOverlay {
  position: absolute;
  margin: 2.5rem 0 0 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 94%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #F2EBDF;
}

.SuperherosPSAContainer:hover .SuperherosPSAOverlay {
  opacity: .9;
  border: 2px solid #733934;
  border-radius: 5px;
  /* margin: .25rem 0 0 0; */
}

.SuperherosPSAOverlayText {
  color: #733934;
  font-size: 1.5rem;
  padding: 1.5rem;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.LogoImage {
  margin: -1rem 0 2rem 2rem;
  height: 10rem;
}

.inProgressButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.gameCompleteNavigation, .gameInProgressNavigation, .gameIncompleteNavigation {
  padding: 0 0 2rem 0;
}

.logo img {
  margin: -1rem 0 2rem 2rem;
  height: 10rem;
}

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin: 0 1rem 1rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Header {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 75%; */
  /* border: solid red; */
  /* margin: 0 0 2rem; */
  font-size: 2.25rem;
  color: #8C6432;
}

.emphasizedText {
  font-style: italic;
}

.playButton, .inProgressButton {
  margin: 2rem 0 0 0;
}

.scoreButton {
  margin: 0 0 .5rem 1rem;
}

.GameContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  font-size: 5rem;
}

.FakeGameContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 100%; */
  font-size: 5rem;
}

.InPublicContainer {
  min-height: 20rem;
  border: .25rem inset #F2EBDF;
  width: 45%;
}

.FakeInPublicContainer {
  min-height: 20rem;
  border: .25rem inset #F2EBDF;
  width: 45%;
}

.QuarantinedContainer {
  min-height: 20rem;
  border: .5rem dotted #96AC35;
  width: 45%;
  color: #96AC35;
  cursor: not-allowed;
  display: block;
}

.FakeQuarantinedContainer {
  min-height: 20rem;
  border: .5rem dotted #96AC35;
  width: 45%;
  color: #96AC35;
  cursor: not-allowed;
  display: block;
}

.SuperherosContainer {
  border: .25rem double #F2EBDF;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  color: #8C6432;
  margin: 2.5rem 0 0 0;
  font-size: 2.5rem;
}

.SuperherosContainer li {
    text-align: left;
}

.SuperheroForm {
  font-size: 1.5rem;
  color: #8C6432;
}

.SuperheroForm input[type=text] {
  padding: 1rem;
  margin: 0 1rem;
  font-size: 1.5rem;
  color: #8C6432;
  border: 2px solid #733934;
  border-radius: 4px;
  outline: none;
  transition: width 0.4s ease-in-out;
}

.SuperheroForm input[type=text]:focus {
  background-color: #F2EBDF;
}

::placeholder {
  color: #8C6432;
  opacity: 1; 
}

.SuperheroForm input[type=submit] {
  padding: 1rem;
  margin: 0 3rem 1.5rem 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  background-color: #9DA65B;
  border: none;
  color: #F2EBDF;
  cursor: pointer;
  border: 2px solid #733934;
  border-radius: 5px;
}

/* Glow Naive Person */
.naivePersonEmoji {
  animation: naiveGlow 5000ms infinite;
}

@keyframes naiveGlow {
  0% { text-shadow: 0 0 -10px red; }
  100% { text-shadow: 0 0 40px red; }
}

/* Glow Sick Person */
.sickPersonEmoji {
  animation: sickGlow 5000ms infinite;
}

@keyframes sickGlow {
  0% { text-shadow: 0 0 -10px green; }
  100% { text-shadow: 0 0 40px green; }
}

/* make safe person and terminal person opaque */
.safePersonEmoji, .terminalPersonEmoji {
  opacity: .5;
}

.FakeSafePersonEmoji {
  opacity: .5;
  padding: .5rem;
}

/* Un-Glow Quarantined Person */
.quarantinedPersonEmoji {
  animation: quarantinedGlow 18000ms infinite;
  opacity: .5;
  padding: .5rem .5rem .5rem 1.5rem;
}

.FakeQuarantinedPersonEmoji {
  opacity: .5;
  text-shadow: 0 0 40px red;
  padding: .5rem .5rem .5rem 1.5rem;
}

@keyframes quarantinedGlow { 
  0% { text-shadow: 0 0 40px red; }
  100% { text-shadow: 0 0 -10px red; }
}

.quarantinedPersonCountdown {
  opacity: .25;
  color: red;
  padding: .5rem 1.5rem .5rem 1rem;
}

.FakeQuarantinedPersonCountdown {
  opacity: .25;
  color: red;
  padding: .5rem 1.5rem .5rem 1rem;
}

.quarantinedPersonPlaceholderEmoji {
  opacity: 0;
}

.savedPersonEmoji, .safePersonEmoji, .naivePersonEmoji, .sickPersonEmoji, .terminalPersonEmoji, .quarantinedPersonPlaceholderEmoji {
  padding: .5rem;
}

.fakeGameEmojis {
  padding: .5rem;
}

/* Conditional Directions based on Mobile Responsiveness */

@media (min-width: 501px) {
  .mobile-only {
    display: none;
  }
}

@media (max-width: 500px) {

  .desktop-only {
    display: none;
  }

}

/* Mobile Responsiveness */

@media (max-width: 500px) {

  body {
    margin: 0 1rem;
  }

  button {
    padding: .5rem;
    font-size: 1.1rem;
    cursor: pointer;
  }

  .inProgressButton {
    padding: .35rem .5rem;
  }

  /* span { 
    display: inline-block;
  } */

  h4 {
    margin: 1.25rem 1rem;
    font-size: 3rem;
  }

  h6 {
    font-size: 1.25rem;
  }

  .HomePSAOverlayText, .SuperherosPSAOverlayText {
    font-size: 1rem;
    padding: 1rem;
  }

  .SuperherosPSAOverlay {
    margin: 2.5rem 0 0 0;
    height: 91%;
  }

  .LogoImage {
    margin: -1rem 0 2rem 2rem;
    height: 6rem;
  }

  .Header {
    text-align: center;
  }

  .App {
    margin: 0 .25rem 1rem;
  }

  .GameContainer {
    width: 90%;
    flex-direction: column;
    align-items: center;
    font-size: 3.75rem;
  }

  .FakeGameContainer {
    /* width: 90%; */
    flex-direction: column;
    align-items: center;
    font-size: 3.75rem;
  }

  .InPublicContainer, .FakeInPublicContainer {
    width: 100%;
    margin: 0 0 1rem;
  }

  .QuarantinedContainer, .FakeQuarantinedContainer {
    width: 100%;
  }

  .gameCompleteNavigation {
    display: flex;
    flex-direction: column;
  }

  .SuperheroForm, .SuperheroForm input[type=text], .restartGame {
    font-size: 1rem;
  }

  .SuperheroForm input[type=text] {
    margin: 0 0 1rem 0;
  }

  .SuperheroForm input[type=submit] {
    font-size: 1rem;
    margin: 1rem 0rem 1rem 1rem;
  }

  .scoreButton {
    margin: 1.25rem 0 1rem 0;
  }

  .rePlayButton {
    margin: 1.25rem 1rem 0 1rem;
  }

  .SuperherosContainer {
    padding: 1rem 1rem;
  }

  .SuperherosContainer h4 {
    font-size: 2rem;
  }

  .SuperherosContainer li {
    font-size: 1rem;
  }

  .fakeGameEmojis {
    padding: .25rem;
  }

  .savedPersonEmoji, .safePersonEmoji, .naivePersonEmoji, .sickPersonEmoji, .terminalPersonEmoji, .quarantinedPersonPlaceholderEmoji {
    padding: .25rem;
  }

  .quarantinedPersonEmoji {
    padding: 0 .5rem 0 3rem; 
  }

  .quarantinedPersonCountdown {
    padding: 0 3rem 0 0; 
  }

}